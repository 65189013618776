<template>
  <base-form-item :field="field">
    <template v-if="value">
      <span style="word-wrap: break-word">{{ value }}</span>
    </template>
    <template v-else>
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </template>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm],
  name: 'index',
  components: {BaseFormItem},
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

</style>
